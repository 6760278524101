

































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { UserModule } from '@/store/modules/user'
import { Buyer, GiftCard, PullRequest } from '@/models/index'
import { clipboardSuccess } from '@/utils/clipboard'

@Component({
  name: 'Dashboard',
  components: {
    GiftCardSelect: () => import('@/components/GiftCardSelect/index.vue'),
    ActionsPullRequest: () => import('./components/ActionsPullRequest.vue')
  }
})
export default class extends Vue {
  pullRequests: PullRequest[] = []

  giftCards: GiftCard[] = []
  giftCardsLoading = false

  private clipboardSuccess = clipboardSuccess

  private searchBuyerName = ''
  filters = {
    page: 1,
    per: 20,
    buyerId: '',
    status: '',
    giftCardId: '',
    dateRange: null,
    code: null,
    amount: null
  }

  private statusOptions = [
    { label: 'Succès', value: 'success'},
    { label: 'En traitement', value: 'processing'},
    { label: 'En attente', value: 'waiting'},
    { label: 'Rupture de stock', value: 'out_of_stock'},
    { label: 'Erreur', value: 'error'}
  ]

  private pullRequestRowColor: any = {
    success: 'success-row',
    processing: 'processing-row',
    waiting: 'waiting-row',
    refunded: 'refunded-row',
    out_of_stock: 'stock-row',
    error: 'error-row'
  }

  get user() {
    return UserModule
  }

  get roles() {
    return UserModule.roles
  }

  tableRowClassName({row, rowIndex}: any): string {
    return this.pullRequestRowColor[row.status]
  }

  created() {
    this.fetchPullRequests()
    this.getGiftCards()
  }

  async fetchPullRequests() {
    const createdAtGte = this.filters.dateRange ? this.$moment(String(this.filters.dateRange![0])).startOf('day').format('YYYY-MM-DD HH:mm:ss') : ''
    const createdAtLte = this.filters.dateRange ? this.$moment(String(this.filters.dateRange![1])).endOf('day').format('YYYY-MM-DD HH:mm:ss') : ''

    const { data } = await PullRequest
        .includes(['buyer', 'giftCard'])
        .where({
          buyerId: this.filters.buyerId,
          giftCardId: this.filters.giftCardId,
          status: this.filters.status,
          createdAt: { gte: createdAtGte, lte: createdAtLte },
          cardCode: this.filters.code,
          amountCents: this.filters.amount ? this.filters.amount * 100 : null
        })
        .order({createdAt: 'desc'})
        .page(this.filters.page)
        .per(this.filters.per)
        .all()

    this.pullRequests.push(...data)
  }

  private async getGiftCards() {
    this.giftCardsLoading = true
    const {data} = await GiftCard.per(500).page(1).order('name').all()

    const tempList: any = {}
    data.forEach(e => {
      const firstChar = e.name.charAt(0)
      if (!tempList[firstChar]) tempList[firstChar] = {label: firstChar, options: [e]}
      else tempList[firstChar].options.push(e)
    })

    this.giftCards = Object.values(tempList)
    this.giftCardsLoading = false
  }

  private async getBuyerByName(buyerName: string, cb: any): Promise<void> {
    if (!buyerName) cb([])

    const {data}: any = await Buyer
        .where({name: {prefix: buyerName}})
        .all()

    cb(data.map((e: any) => {
      return {value: e.name, id: e.id}
    }))
  }

  truncateID(id: string): string {
    return `${id.slice(0, 4)} .... ${id.substr(id.length - 4)}`
  }

  private handleSelectBuyer(buyer: Buyer) {
    this.filters.buyerId = buyer.id
    this.handleFilter()
  }

  private handleClearBuyer() {
    this.filters.buyerId = ''
    this.handleFilter()
  }

  private handleGiftCardSelect(giftCardId: string) {
    this.filters.giftCardId = giftCardId
    this.handleFilter()
  }

  private handleFilter() {
    this.filters.page = 1
    this.pullRequests = []
    this.fetchPullRequests()
  }

  private refresh() {
    this.filters = {
      page: 1,
      per: 20,
      buyerId: '',
      status: '',
      giftCardId: '',
      dateRange: null,
      code: null,
      amount: null
    }
    this.pullRequests = []
    this.fetchPullRequests()
  }
}
